

/* .partners {
    overflow: hidden;
    text-align: center;
    padding: 0px 0;
    animation: slideInUp 2s;
    margin-top: 50px;
} */

.custom-partners {
    margin-top: 50px !important;
  }

.box{
    border-radius:10px;
}
.why-us, .box-item{
   display: flex;
}

.why-usa, .box-item{
    display: flex;
 }
.tags
{
      display: flex;

    gap: 20px;
}
.driven-skill{
    display:flex;
    justify-content: space-between;
}
.skill-content{
    display: flex;
    flex-direction:column;
    width:50%;
    animation:slideInRight 1s;
}
.driven-skill .header-text h1{
    color:#1E6069;
}
.broker-tag
{
     background: #B8DCDB;
    
    font-weight: 600;
  
    padding: 10px 22px;
    border-radius: 23px;
    display: inline-block;
}
.broker-tag:hover {
    animation:zoomIn 2s;
}
.box-item{
    justify-content: space-around;
    border-bottom: 2px solid #ccc;
    width: 50%;
}
.box-item:first-child{
    border-top:2px solid #ccc;
}
.box-item > h4{
    width:50%;
    padding-left:2%; 
    animation:slideInLeft 1s ;
   
}

.box-item > .inner-item{
    width:50%;
}
.inner-item{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding: 10px;
    animation:slideInRight 1s;
}
.why-us{
  
    flex-wrap: wrap;
}
.why-usa{
  
    flex-wrap: wrap;
}
.why-content{
    width:100%;
}
.why-content h3{
    margin :0;
}
.why-content p{
    margin :10px 1px;
}
.why-box{
    display: flex;
  width:50%;
    padding:2% 0;
    border-bottom: 4px solid #E9F0F0;
}
.why-us{
    /* border-top:4px solid #E9F0F0; */
    margin-left: 60px;
    width: 90%;
    height: 100%;
}

.why-usa{
    /* border-top:4px solid #E9F0F0; */
/* margin-left: 120px; */
justify-content: center;
}


.why-box img{
    animation: rotateIn 1s;
    margin:5px 20px ;
  
}
.why-box:hover img{
   animation: rotateIn 5s;
  
}

.why-box:hover {
    animation: pulse 1s;
    display: flex;
    gap:10px;
}
.achievement{
    display: flex;
    margin:10px 0;
    background-color: #1E6069;
    border-radius: 8px;
    padding: 40px;
    justify-content:space-around;
   
}
.achievement div:hover {
    animation: pulse 1s;
}
.achievement h1{
    color:white;
    padding:10px 10px 4px 10px; 
    margin:2px;
    font-family: 'Ember';
    font-weight: 400;
    font-size: 50px;

}
.achievement h5{
    margin:0;
padding:10px 10px 4px 10px;;
    color:white;
    font-weight:normal;
    padding-bottom:31px;
}
.achievement :nth-child(3) h5{
    padding-left: 23px;
}
.tbox{
    padding:1%;
    display: flex;
 align-items: center;
}
#nobackground{
    background-image:none;
    
}
#work{
    background-image:url(../assests/images/work.png);
    background-repeat: no-repeat;
    background-position:right;
    object-position: right;
   
}

@media screen and (max-width:900px){
    .driven-skill{
        flex-direction: column;
    }
    .skill-content{
        width: 100%;
        gap:20px;
        padding: 0 30px;
    }
}
@media screen and (max-width:600px) {
    .why-us, .achievement{
        /* margin:0px 0px; */
        flex-direction: column;
    }
    .why-usa, .achievement{
        margin:60px 10px;
        flex-direction: column;
    }

    .why-box{
        width:100%;
        padding:20px 0;
    }
   .box-item{
       width:100%;
    }
    #work{
        background-image:none;
    }
    .achievement{
        text-align:center;
    }
    .tbox{
        flex-direction: column;
        align-items: start;
    }
}


@media (max-width: 480px) {
    .achievement h1 {
        font-size: 35px !important; 
    }
  }



