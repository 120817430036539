.testimonials {
  text-align: center;
  margin: 3%;
  flex-wrap: wrap;
  gap: 10px;
  padding: 60px 0;
}
.cards {
  text-align: center;
  margin: 3%;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: -65px;
}
/* .feedback{
  padding-top:100px;
  margin: 0 auto;
  width:800px;
}
.feedback input{    
  padding: 1rem 1rem;
    margin: 17px 0;
    font-size: 20px;
}
.feedback textarea{
font-size: 20px;
    min-height: 216px;
margin: 1rem 0;
} */
/* .feedback p{
  margin:20px 0;
} */
.subheading{
  padding-top: 40px;
}
.subheading button{

  width:fit-content;
  margin-top:15px;
  }
.testimonials p{
  margin:auto;
}
.review-card:hover{
    animation:pulse 1s;
}
  .review-card {
    background: #fff;
 
    box-shadow: 0 8px 30px -7px #d2d3d4;
    padding:10px;
    border-radius: 20px;
    border: 0;
    text-align: center;
  }
    .card-img {
      max-width: 100px;
    
      margin: 15px auto 0;
    
   
      height: 60px;
    }
      .rcard-body h3 {
      color: rgb(6, 85, 91);
   
      line-height: 1.3;
      }
      .rcard-body h3, .rcard-body h5 {
      margin:10px;
      }
     .rcard-body p {
     
      color: #555;
      padding-bottom: 15px;
      width:80%;
      margin:auto;
    }


    .swiper {
  width: 100%;
margin-top:5% ;
}

.swiper-slide {
  padding: 9px 35px;
  background-position: center;
  background-size: cover;
  
  
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-pagination{
  position: relative!important;
  margin:30px 0;
  
}
.swiper-pagination-bullet{
  
  width: 10px !important;
  height:10px !important;
}
.swiper-pagination-bullet-active{
  background-color:rgb(23, 119, 119)!important;
}
.swiper-slide-shadow-left{
  background-image: linear-gradient(to right,rgba(227, 245, 245, 0.988),rgba(246, 246, 246, 0.064))!important;
}
 .swiper-slide-shadow-right{
  background-image: linear-gradient(to left,rgba(227, 245, 245, 0.988),rgba(246, 246, 246, 0.064))!important;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-size:20px!important;
font-weight:bold;
}
.swiper-button-next, .swiper-button-prev {
  
    width: calc(var(--swiper-navigation-size))!important;
    color: white!important;
    background-color:#058fa198; 
    border-radius: 50%;
}
@media screen and (max-width:550px) {
.review-card{
  margin:1%;
}
.swiper-slide {
  padding: 0;

}
}

