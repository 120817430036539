


ul, li{
  list-style: none;
  padding: 0;
}

.tcontainer{
  display: flex;
  justify-content: center;
  align-items: center;
padding: 2rem;
}


.sessions{
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
}
.sessions li{
    padding-bottom: 3.5rem;
    border-left: 2px solid black;
    position: relative;
    padding-left: 42px;
    margin: 0 0 0 10px;
}
.sessions li:last-child{
    border: 0px;
    padding-bottom: 0;
  }
.sessions li:before{
    content: '';
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 50%;
    position: absolute;
    left: -16px;
    top: 0px;
  }

.time{
  color: black;
}
.time h5{
  margin:10px 0;
  animation:slideInRight 1s;
}
.sessions p{
    animation: slideInUp 2s;
    width: 80%;
}
 @media screen and (min-width: 600px){
    .time{
    margin-bottom: 0.3rem; 
  }
 

}

  
