.service-box{
    border:5px solid  rgba(2, 96, 109, 0.542);
    border-radius:6px;
    width:350px;
    text-align: center;
    margin: 40px 20px;
 
}
.service-box p{
    font-size:15px;
    padding:20px 15px;
    margin:auto;
}

.icon{
    position: absolute;
    margin: -51px;
    background-color:white;
    width:90px;
    padding: 20px;
    
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding:3%;
}
.card {

  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}

.carda {

    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.cover{
    width:400px;
    animation:slideInRight 5s;
}

.service-wrapper{
    display:flex;
    justify-content:center;
    padding:20px;
    flex-wrap: wrap;
}




h3{
    padding:35px 0 11px;
    margin-bottom: 0;

}
.consult-services{

    justify-content:center;
}
.consult-services .content{
    padding:50px 50px 0;
    margin-bottom: -70px;
}
.services > h1{
    text-align:center;
}
.box{
    margin:20px 10px ;
    padding:20px;
    background-color:#E9F0F0;
    
}
#background{
   
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;

}
@media screen and (max-width:900px){
    .consult-services .content{
        flex-direction:column;
    }
    
    .consult-services .content {
    padding: 0;
}
}
@media screen and (max-width:600px)  {
    .icon {
    position: absolute;
    margin: -51px;
   
    padding: 20px;
    }
    
    h3 {
    padding: 37px 18px 11px;
}
.cover{
     width:  100%;
    }
.services{
    padding: 0;
}
.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3%;
} 
.service-box {
    border: 5px solid rgba(2, 96, 109, 0.542);
    border-radius: 6px;
 
    text-align: center;
    margin: 23px 4px;
}
.consult-services .content {
    padding: 10px;
}
.service__content {

    padding: 0px;
}
.box{
    margin:10px ;
   
   
}
#background{
     background-size: cover;
    background-position:left;
}
}
@media  screen and (min-width:1200px) {
    .cover{
        width:500px;
    }
}

@media (max-width: 480px) {
    .services h1 {
        font-size: 35px !important; 
    }
  }

  @media (max-width: 480px) {
    .box h1 {
      font-size: 28px !important;
      
    }
  }