.popup{
	position: fixed;
	top:57px;
	width: 100%;
	height: 100%;
	background-color:rgba(0,0,0,0.2);
	
	justify-content: center;
	align-items: center;
	z-index:50;
	left:0;
}


.popup-inner{
	animation:slideInDown 1s;
	margin:auto;
	background-color:white;
	padding:30px 20px 20px;
	max-width:440px;
	width:80%;
	display: flex;
    flex-direction: column;
	border-radius:6px;
	box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);
	z-index: 50px;
}
.close-btn{
	align-self: flex-end;
	background-color:#197d8a;
	border-radius:50%;
	width:40px;
	font-weight: bolder;
	height:40px;
	color:white;
	border:1px solid #1e6069;


}

.contact{
	    padding: 0 27px;
	display: flex;
    flex-direction: column;
	
	background-color: #fff;
	
}





.contact h2{
	position: relative;
	padding: 0px 0 10px;
	margin: 0 0 10px 0;
	color:black;
	text-align: center;
}

.contact h2:after{
	content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 4px;
    width: 50px;
    border-radius: 2px;
    background-color: #1e6069;
}
#error {
	color: red !important;;
	font-size: small !important;
	text-align: left;
    margin: 0 10px;
}

.field{
	
	border: 2px solid rgba(21, 21, 21, 0.106);
	outline: none;
	background-color: rgba(230, 230, 230, 0.6);
	padding: 0.5rem 1rem;
	
	margin: 9px 0;
	transition: .3s;
	border-radius:6px;
}

.field:hover{
	background-color: rgba(0, 0, 0, 0.1);
}
.field:focus{
	border: 2px solid #1e6069;
	background-color:white;
}

textarea{
	min-height: 100px;
	margin:1rem 0;
}


.contact-box .btn:hover{
    background-color: #035c5c;
}



@media screen and (max-width: 880px){
	.contact-box{
		grid-template-columns: 1fr;
	}
	
}
@media screen and (max-width:600px){
	.contact{
		padding:0;
	}
	.popup-inner{
		padding:30px;
	}
	.field{
		width: 100%;
		margin:7px 0;
	}
	.close-btn{
		width:25px;
		height:25px;
	}
	
	
}