

/* .btn{
font-size:24px;
font-style:bold;
text-align:center;
background-color:#1E6069;
color:white;
    padding: 5%;
    border-radius: 13px;
border:3px solid #1E6069;
}
.btn:hover{
    background-color: white;
    border: 2px solid #1E6069;
    color:#1E6069;
} */
.btn{
    box-sizing: border-box;
    vertical-align: baseline;
    text-align: center;
    display: inline-block;
    color: white;
    background-color: #1E6069;
    padding: 17px;
    border: 1px solid #1E6069;
    letter-spacing: 0.04em;
    font-weight: 500;
    border-radius: 5px;
    font-size: 20px;
    position: relative;
    transition: all .2s ease;
    font-family: "Ember";
 } 
 button:hover{
  background-color: #0d545d;
  animation:pulse 1s;
 }