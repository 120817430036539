@import "animate.css";
h1 {
  font-size: 25px;

  font-family: "EmberBold";
}
p {
  margin: 1px 0 1px 0;
  font-family: "Ember";
  font-size: 18px;
  width: 100%;
  /* text-align: center; */
}
/*LANDING SECTION*/
.layout {
  display: flex;
  flex-direction: column;
  padding: 4%;
  margin-bottom: -90px;
}
#home {
  font-family: "Ember";
  background-size: cover;
  padding: 40px 15px 4px 15px;
  
}

.landing {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  padding: 2% 1%;
}
.landing_image {
  background-size: cover;
  background-repeat: no-repeat;
  top: 40px;
  object-position: right;
  animation: fadeInRight 1s;
  position: absolute;
  right: 0px;
}

.header-text {
  animation: fadeInUp 1s;
  max-width: 670px;
  line-height: 1.32;
  font-weight: 500;
  margin: 2% 1%;
  z-index: 20;
}
.header-text h1 {
  animation: none;
  margin: 5px 0px;
}
.landing-logo {
  width: 500px;
}
.hire-btn {
  padding: 20px 0px;
}

#home p {
  margin: 20px 0;
  width: 80%;
  color: rgb(87, 85, 85);
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
  
}
.hire-btn a {
  color: #1e6069;
  padding-left: 5px;
}
@media only screen and (max-width: 900px) {
  .landing_image {
    top: 45px;
  }
  .landing-logo {
    width: 400px;
  }
  h1 {
    font-size: 34px;
  }

  /* h3 {
    margin: 2px;
  } */
  p {
    font-size: 18px;
    width: 100%;
  }
  .header-text {
    max-width: 350px;
    font-weight: 500;
    padding: 2%;
  }
  #home {
    background-image: none;
    padding: 40px 0 10px 0;
  }
}
@media screen and (max-width: 700px) {
  .landing_image {
    top: 78px;
  }
  .landing-logo {
    opacity: 0;
  }
  .top {
    height: 10px !important;
  }
  .items {
    visibility: hidden;
    height: 10px;
  }
  #home p {
    font-weight: 500;
  }
}
@media screen and (max-width: 500px) {
  .landing_image img {
    opacity: 0;
  }
  .header-text {
    width: 100%;
  }
}




@media (max-width: 480px) {
  .header-text h1 {
      font-size: 35px !important; 
  }
}

@media (max-width: 480px) {
  .testimonials h1 {
      font-size: 30px !important; 
  }
}


