.cardl {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    width: 300px; /* Initial width for smaller screens */
  }
  
  .card-imagel {
    width: 100%;
    height: 200px;
    object-fit: cover; /* Crop image to fit container */
  }
  
  .card-contentl {
    padding: 15px;
  }
  
  .h3l {
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Media Queries for responsiveness */
  @media (min-width: 768px) {
    .cardl {
      width: calc(33% - 20px); /* Adjust width for larger screens */
      margin: 0 10px 20px;
    }
  }