* {
  font-family: "Ember";
}

/* 
.subscribe{
    padding:4% 0 0 0;
    display: flex;
    gap:5px;
    justify-content: center;
}
input{
    height:40px;
    padding:5px;
    width:500px;
}
#button{
    margin-left:20px;
    padding:11px;
   
}
@media only screen and (max-width: 900px) {
    .subscribe{
        flex-direction:column;
        margin: auto;
        gap:12px;
        width:90%;
    }
    #button{
      margin:10px;
        width:150px;
        margin:auto;
    }
    input{
        width:90%;
    }
} 
#footer {
  width: 100%;
  background: black;
  text-align: center;
}
#footer p {
  margin-top: 0px;
}
.footer-link-container {
  width: 80%;
  display: inline-block;
}
.main-flexbox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flexbox-item {
 display: flex;
    flex-direction: column;
    margin-top: 3rem;
    height: 300px;
}
.flexbox-item:first-child{
  justify-content:start;
  margin-top: 54px!important;
}
.footer-link {
  margin: 3px auto;
  object-fit: contain;
}

.text {
  
  font-weight: 200;

  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
}
.heading-footer {
  font-family: "EmberBold";
 
  line-height: 36px;
 
  text-align: left;
  color: #ffffff;
}
.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.li {
  list-style: none;
 font-size: 20px;
  line-height: 24px;

  text-align: left;
  color: #ffffff;
  padding:7px 0;
}
.li:hover {
  color: #1E6069;
}
.Links {
  text-decoration: none;
  color: #ffffff;
}
.Links:hover {
  color: #ffffff;
}
.Link_Li{
  text-decoration: none;
}
.bootomDiv {
  text-align: center;
}
.hr {
  display: inline-block;
  width: 100%;
  height: 0.2px;
  background-color: #ffffff;
  border: 0.5px solid rgba(229, 229, 229, 0.4);
}
.bottom-flexbox {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom-flexboxitem {
  margin: 0px;
  padding: 0px;
}
.text-bottom {

  font-weight: normal;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 0px;
}
.social-logos {
  margin: 0px;
  padding: 0px;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}
.social-logos:hover {
  cursor: pointer;
}
/* Media Queries */
/* @media screen and (max-width: 1033px) {
  #footer {
    height: 100%;
  }
  .footer-link {
    margin: 0.5rem;
  }
  .main-flexbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .flexbox-item {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 50%;
    margin-top: 0.5rem;
  }
  .li {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .hr {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 583px) {
  .bottom-flexbox {
    flex-direction: column;
  }
  .bottom-flexboxitem {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .footer-link-container {
  width: 90%;
  display: inline-block;
}
} * */

footer {
  background-color: #1e1e1e;
  padding: 0px 32px 5px 31px;
}

.footer-link {
  display: flex;
}

.flex-items a {
  transition: width 2s;
  color: #ccc;
}

.flex-items a:hover {
  color: white;
  padding-bottom: 4px;
  border-bottom: 2px solid white;
}

nav {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 -10px;
}

.flex-items {
  flex: 0 1 auto;
  padding: 0 10px 10px;
  color: white;
}

.footer-link-holder {
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  flex-direction: column;
  height: 300px;
  justify-content: flex-start;

  font-size: inherit;
  color: white;
}

.logo-holder a {
  text-align: left;
}
.logo {
  margin: 10px 55px 0px 0;
}

/* .logo:hover {
  animation: bounce 0.1s ease-in-out;
}  */

/* .logo:hover {  */
  /* animation: zoomIn 5s;  */
  /* animation: zoomIn 5s forwards; */
  /* transform: scale(1.1);  */
 /* } */
/* .logo-holder .logo:hover {
  animation: zoomIn 1s;
} */

.img-logo{
  /* margin: 10px 55px 0px 0; */
  /* border: 1px solid #ccc; */
  margin-top: 50px;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.img-logo:hover {
  transform: scale(1.1);
}

.logo {
  margin: 10px 55px 0px 0;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.flex-items ul {
  margin: 0;
  padding: 0;
}

.flex-items li {
  list-style: none;
  margin-bottom: 1em;
  padding: 0;
}

/* .social-logos-container {
  display: flex;
  margin: 2%;
  padding: 0px;
  width: 23px;
  height: 23px;
  margin-left: 9px;
  margin-right: 0.6rem;
  margin-top: -17px;
} */

.social-logos {
  display: flex;
  margin: 2%;
  padding: 0px;
  width: 23px;
  height: 23px;
  margin-left: 9px;
  margin-right: 0.6rem;
/* <<<<<<< HEAD
  margin-top: -17px;
}

.social-logos :hover {
  cursor: pointer;
  transform: scale(1.1);

  /* animation: zoomIn 1s; */
}

.social-logos :hover {
  cursor: pointer;
  transform: scale(1.1);

  /* animation: zoomIn 1s; */
}

.bottom-bar p {
  color: #ccc;
  margin: 20px 5px;
  font-size: 16px;
  text-align: center;
}

.imp-logos {
  display: flex;

  gap: 10px;
  margin: 5 0 0 10px;
  padding: 0 0 10px 0;
}

@media screen and (max-width: 768px) {
  footer {
    padding-left: 16px;
  }

  .footer-link {
    flex-direction: column;
  }

  .logo-holder {
    height: fit-content;
  }

  .logo-holder a {
    text-align: left;
  }

  .imp-logos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 5px 18px;
    gap: 10px;
  }

  .logo {
    margin: 5px 0;
  }

  nav {
    justify-content: space-between;
    margin: 0 0 0 25px;
  }

  .flex-items {
    padding: 2px;
  }

  .social-logos {
    margin: 10px;
    padding: 0 5px;
  }

  .bottom-bar {
    padding: 10px;
  }

  a:hover {
    text-decoration: none;
  }

  .ratings {
    width: 320px !important;
    height: 150px !important;
  }
}
/* <<<<<<< HEAD
=======

/* styles.css */

.containerf {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  /* text-align: center; */
  margin-bottom: 20px;
  color: #333;
}

.card-sectionf {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardf {
  flex: 1;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cardf:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardf p {
  margin: 0;
  word-break: break-word;
}

@media (max-width: 768px) {
  .card-sectionf {
    flex-direction: column;
  }
}

/* Reset some default browser styles */
/* body, h2, p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f9;
  color: #333;
} */

/* .address-section {
  padding: 50px 20px;
  background-color: #fff;
  text-align: center;
}

.address-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.address {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 350px;
  flex: 1;
  min-width: 300px;
  margin-top: 20px;
} */

/* .address-icon {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.address-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
} */

/* .address-details {
  text-align: left;
  flex-grow: 1;
}

.address-details h2 {
  font-size: 1.6em;
  margin-bottom: 5px;
  color: #333;
}

.address-details p {
  font-size: 14px;
  color: #ccc;
  line-height: 1.6;
  text-align: center;
} */
/* .separator {
  align-self: center;
  font-size: 3em;
  color: #ccc;
} */

/* Responsive adjustments */
@media (max-width: 768px) {
  /* .address-container {
    flex-direction: column;
    align-items: center;
  } */
  /* .separator {
    display: none;
  } */
}


.address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #1e1e1e; /* To match the background color in your image */
  color: #fff;
  flex-wrap: wrap; /* Prevent wrapping of addresses */
  margin-left: -27px;
}

.address {
  flex-grow: 1; /* Allow addresses to grow to fill available space */
  text-align: center;
  margin: 0 10px; /* Adds space between addresses */
}

.address p {
  font-size: 14px;
  color: #ccc;
  line-height: 1.6;
}

.separator {
  color: #b5b5b5;
  font-size: 3rem;
  padding: 0 10px;
  align-self: stretch; /* Ensures the separator takes up the full height of the addresses */
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .address {
    width: 45%; /* Two addresses per row on medium screens */
  }
  
  .separator {
    display: none; /* Hide separators on medium screens */
  }
}

@media (max-width: 768px) {
  .address {
    width: 100%; /* One address per row on small screens */
  }
  
  .separator {
    display: none; /* Hide separators on small screens */
  }
}
