h4 {
  position: relative;
  padding: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h4 span {
  display: block;
  /* font-size: 0.5em;
    line-height: 1.3; */
}
h4 em {
  font-style: normal;
  font-weight: 600;
}

/* === HEADING STYLE #2 === */
.two h4 {
  /* text-transform: capitalize; */
}

.two {
  margin: 0px 12px 0 12px;
}
.two h4:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
}

.two h4 span {
  font-size: 13px;
  /* font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 3em;
    padding-left: 0.25em;
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 10px; */
}
.two h3 {
  font-size: 14px;
  /* font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 3em;
    padding-left: 0.25em;
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 10px; */
}
.alt-two h4 {
  text-align: center;
}
.alt-two h4:before {
  left: 50%;
  margin-left: -30px;
}

.main-content {
  justify-content: left;
}

.main-scroll {
  overflow-y: scroll !important;
  max-height: 490px;
}

.select-box {
  transition: 1s ease;
  cursor: pointer;
}

.select-box:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease;
}
.popup-content {
  border-radius: 11px !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 7px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #006567; 
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */
