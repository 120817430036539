/* src/Card.css */
.card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    margin: 16px;
  }
  
  .card:hover {
    transform: translateY(-8px);
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-content {
    padding: 16px;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 0 0 8px;
    text-align: center;
  }
  
  .card-description {
    font-size: 1em;
    color: #555;
    width: 100%;
    text-align: center;
  }
  

  